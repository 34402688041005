import axios from "axios";
import proService, {
  publicProService,
  proService2,
  proService3,
  marketplaceService,
} from "../helpers/axios";
import { joinObjects, stringifyUrl } from "hd-utils";

const MAP_TOKEN =
  "pk.eyJ1Ijoic3VoYWlsLWFwcCIsImEiOiJjazl0cjFrOWMxZ2p1M2huMW4xZTl2MzgzIn0.GMPVuCC_3EpJ4HZA8KZoPg";

export const getPlacesFromMap = ({ place }) => {
  if (place.length > 2) {
    return axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${place}.json?access_token=${MAP_TOKEN}&country=sa&language=ar&types=poi,place`
    );
  } else {
    return true;
  }
};

export const getFilterdPrice = ({
  topLeft,
  bottomLeft,
  Type,
  Period,
  FromPrice,
  ToPrice,
}) => {
  // let PriceFilter = `&fromPrice=${FromPrice}&toPrice=${ToPrice}`;
  let PriceFilter = "";
  let TypeFilter = "";
  if (FromPrice >= 1000) {
    PriceFilter = `&fromPrice=${FromPrice}`;
  }
  if (ToPrice <= 9000) {
    PriceFilter += `&toPrice=${ToPrice}`;
  }
  if (Type !== "الكل") {
    TypeFilter = `&type=${Type}`;
  }
  return proService2.get(
    `transactions/boundaries?boundaryFrom=${topLeft}&boundaryTo=${bottomLeft}${TypeFilter}${
      Period == "before_that" ? "" : `&period=${Period}`
    }${PriceFilter}`
  );
};
export const searchTransactoins = (searchParams) => {
  return proService2.get(`api/transactions/search?` + searchParams);
};
export const searchParcels = (searchkeys) => {
  return proService2.get(`api/parcel/search`, { params: { ...searchkeys } });
};
export const getParcelTransactions = (parcelObjectId) => {
  return proService.get(`transactions?parcelObjectId=${parcelObjectId}`);
};
export const regionsData = () => {
  return proService.get(`regions`);
};

export const getUserData = () => proService.get(`accounts/user/profile`);

export const getAllUserBadges = () =>
  proService.get(`Badges/GetAllCurrentUserBadgesProgress`);

export const getUserBadge = () =>
  proService.get(`Badges/GetCurrentUserProfileBadgesInfo`);

export const getUserBadgeStatus = () =>
  proService.get(`Badges/GetRegistrationFormStatus`);

export const getRegionsLookup = () => proService.get(`Badges/GetRegionsLookup`);

export const updateBadgesContactUsForm = (data) =>
  proService.post(`Badges/updateBadgesContactUsForm`, data);

export const addAttachment = (file) => proService.post("api/attachments", file);

export const updateUserData = (data) =>
  proService3.put(`accounts/user/profile`, data);

export const getProjectData = (projectCode) => {
  return proService.get(`org/booking/project/public/code/${projectCode}`);
};

export const getPublicParcelsData = (projectId, organisationId) =>
  publicProService.get(
    `org/${organisationId}/booking/project/${projectId}/map/public/parcels`
  );

export const getPublicProjectStatisticsAPI = (projectId) =>
  publicProService.get(`org/booking/project/${projectId}/public/statistics`);

export const getSettings = () => proService2.get(`/settings/app`);

export const getComplaintsReason = (entityType) =>
  proService.get(`/complaints/${entityType}/reasons`);

export const addComplaints = (payload) =>
  proService.post(`/complaints`, payload);

export const addLeadContactDetails = (payload) =>
  proService.post(`/org/booking/SaveContactDetailsAsync`, payload);

export const getJSReport = (data) =>
  proService.post(`/api/Report/DownloadJSReport`, data);

export const GET_PROJECT_LIST = (
  OrderByField = 3,
  OrderByDirection = 1,
  Offset = 0,
  Limit = 10
) => {
  return marketplaceService.get(
    stringifyUrl(
      {
        url: "api/marketplace/project/list",
        query: joinObjects({
          OrderByDirection,
          Offset,
          Limit,
          OrderByField,
        }),
      },
      {
        skipNull: true,
        arrayFormat: "none",
      }
    )
  );
};

export const GET_PROJECT_DETAILS = (id) =>
  marketplaceService.get(`api/marketplace/project/${id}`);

export const POST_LOCATION_COLLECTION = (data) =>
  publicProService.post(`/api/locationscollection`, data);

export const GET_LOCATION_COLLECTION = (id) =>
  publicProService.get(`/api/locationscollection?Id=${id}`);

export const GET_GPTV_DATA = (id) =>
  proService.get("/api/GPTV/FormDetails");

export const UPDATE_GPTV_DATA = (data) =>
  proService.post("/api/GPTV/AddFormTracking", data);