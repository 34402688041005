import React, { Suspense, useState, Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useFirebaseContext, useMapContext } from "appContext";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import UnderMaintenance from "./UnderMaintenance";
import Footer from "./components/Footer";
import * as pages from "./pages";
import { regionsData } from "./api/APIs";
import { useRecoilState, useRecoilValue } from "recoil";
import { RegionsData, currentRegion } from "./data/Atoms";
import moment from "moment";
import { CITIES } from "./constants";
import { Spin } from "antd";
import { useMount } from "use-reacts-hooks";
import { GPTVModal } from "components/GPTVModal/GPTVModal";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { onAuthStateChanged } from "firebase/auth";
import "./App.scss";

const App = () => {
  const { remoteConfig, auth } = useFirebaseContext();
  const Region = useRecoilValue(currentRegion);
  const [firebaseData, setFirebaseData] = useState({});
  const [firebaseDataLoading, setFirebaseDataLoading] = useState(true);

  window.dataLayer.push({
    event: "pageview",
  });

  const [regionDataInit, setRegionData] = useRecoilState(RegionsData);
  const { regData, setRegData } = useMapContext();

  const hideFooter = String(window.location?.pathname)?.includes(
    "landing-page"
  );
  
  const GestRouts = () => {
    return (
      <Switch>
        <Route path="/" component={pages.Home} exact />
        <Route path="/projects/roshan" component={pages.ProjectDetails} exact />
        {/* <Route path="/poc_map_pins" component={pages.POCMapPins} /> */}
        <Route path="/profile/:brokerCode" exact component={pages.Profile} />
        <Route
          path="/:Region/metrics"
          exact
          render={(props) => (
            <pages.Map key={props.match.params.Region} {...props} />
          )}
        />
        <Route path="/metrics" component={pages.Map} exact />
        <Route path="/account/auth" component={pages.Auth} />
        <Route
          path="/:Region/transactions"
          render={(props) => (
            <pages.Transactions
              key={props.match.params.Region}
              regionData={Region}
              regionsData={regData}
              {...props}
            />
          )}
          exact
        />
        <Route
          path="/transactions"
          render={(props) => (
            <pages.Transactions
              key={props.match.params.Region}
              regionData={CITIES["Riyadh"]}
              regionsData={regData}
              {...props}
            />
          )}
          exact
        />
        <Route
          path="/transaction/:transactionNo/:subdivisionNo/:parcelNo/:regionId/:provinceId"
          render={(props) => (
            <pages.Transaction regionData={Region} {...props} />
          )}
        />
        <Route
          path="/transaction/:transactionNo/:subdivisionNo/:parcelNo/:regionId"
          render={(props) => (
            <pages.Transaction regionData={Region} {...props} />
          )}
        />
        <Route path="/:Region/mobile-metrics" component={pages.MobileMap} />
        <Route path="/privacy-policy" component={pages.Policy} />
        <Route path="/agreement" component={pages.Agreement} />
        <Route
          path="/:Region/offer/:offerCode"
          render={(props) => (
            <pages.Offer key={props.match.params.Region} {...props} />
          )}
        />
        <Route
          path="/offer/:offerCode"
          render={(props) => (
            <pages.Offer key={props.match.params.Region} {...props} />
          )}
        />
        <Route
          path="/:Region/parcel/:parcelObjectId"
          component={pages.Parcel}
        />
        <Route path="/parcel/:parcelObjectId" component={pages.Parcel} />
        <Route path="/report/:parcelObjectId" component={pages.Report} />
        <Route path="/market" component={pages.Market} />
        <Route path="/landing-page/61" component={pages.WatheerLandingPage} />
        <Route path="/landing-page/81" component={pages.OsusAlMurjan} />
        <Route
          path="/landing-page/46"
          component={pages.SamaAlRosanLandingPage}
        />

        <Route
          path="/project-details/:projectId"
          component={pages.NewProjectDetails}
        />

        <Route component={pages.NotFound} />
      </Switch>
    );
  };
  const ClientRouts = () => {
    return (
      <Switch>
        <Route path="/" component={pages.Home} exact />
        <Route path="/user-profile" component={pages.UserProfile} exact />
        <Route path="/suhail-badges" component={pages.SuhailBadges} exact />
        <Route path="/projects/roshan" component={pages.ProjectDetails} exact />
        <Route path="/profile/:brokerCode" exact component={pages.Profile} />
        <Route
          path="/:Region/metrics"
          exact
          render={(props) => (
            <pages.Map key={props.match.params.Region} {...props} />
          )}
        />
        <Route path="/metrics" component={pages.Map} exact />
        <Route path="/account/auth" component={pages.Auth} />
        <Route
          path="/:Region/transactions"
          render={(props) => (
            <pages.Transactions
              key={props.match.params.Region}
              regionData={Region}
              regionsData={regData}
              {...props}
            />
          )}
          exact
        />
        <Route
          path="/transactions"
          render={(props) => (
            <pages.Transactions
              key={props.match.params.Region}
              regionData={CITIES["Riyadh"]}
              regionsData={regData}
              {...props}
            />
          )}
          exact
        />
        <Route
          path="/:Region/favorites"
          render={(props) => (
            <pages.Favorites
              key={props.match.params.Region}
              regionData={Region}
              {...props}
            />
          )}
        />
        <Route
          path="/transaction/:transactionNo/:subdivisionNo/:parcelNo/:regionId/:provinceId"
          render={(props) => (
            <pages.Transaction regionData={Region} {...props} />
          )}
        />
        <Route
          path="/transaction/:transactionNo/:subdivisionNo/:parcelNo/:regionId"
          render={(props) => (
            <pages.Transaction regionData={Region} {...props} />
          )}
        />
        <Route path="/:Region/mobile-metrics" component={pages.MobileMap} />
        <Route path="/privacy-policy" component={pages.Policy} />
        <Route path="/agreement" component={pages.Agreement} />
        <Route
          path="/:Region/offer/:offerCode"
          render={(props) => (
            <pages.Offer key={props.match.params.Region} {...props} />
          )}
        />
        <Route
          path="/offer/:offerCode"
          render={(props) => (
            <pages.Offer key={props.match.params.Region} {...props} />
          )}
        />
        {/* <Route path="/parcel/:subdivitionNumber/:parcelNumber" component={Parcel} /> */}
        <Route
          path="/:Region/parcel/:parcelObjectId"
          component={pages.Parcel}
        />
        <Route path="/parcel/:parcelObjectId" component={pages.Parcel} />
        <Route path="/report/:parcelObjectId" component={pages.Report} />
        <Route path="/market" component={pages.Market} />
        <Route path="/landing-page/61" component={pages.WatheerLandingPage} />
        <Route
          path="/landing-page/46"
          component={pages.SamaAlRosanLandingPage}
        />
        <Route path="/landing-page/81" component={pages.OsusAlMurjan} />
        <Route
          path="/project-details/:projectId"
          component={pages.NewProjectDetails}
        />
        <Route component={pages.NotFound} />
      </Switch>
    );
  };

  const userStatusChanged = () => {
    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          user.getIdToken().then(function (accessToken) {
            if (accessToken !== localStorage.getItem("suhail_user_token")) {
              localStorage.setItem("suhail_user_token", accessToken);
              localStorage.setItem("tokenExpiration", moment().unix() + 3600);
              window.location.pathname !== "/account/auth" &&
                window.location.reload();
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const tokenExpirationDate = localStorage.tokenExpiration;
      const currentDate = moment().unix();
      if (
        Number(tokenExpirationDate) < Number(currentDate) ||
        !tokenExpirationDate
      ) {
        userStatusChanged();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, userStatusChanged);

  useMount(() => {
    (async () => {
      try {
        const Resp = await regionsData();
        if (Resp.data) {
          setRegionData(Resp.data.data);
          setRegData(Resp.data.data);
        }
      } catch (error) {
        console.log(error?.message);
      }
    })();
  });

  const fetchRemoteConfigData = () => {
    setFirebaseDataLoading(true);
    remoteConfig.settings = {
      // Use in-memory storage, bypassing IndexedDB
      minimumFetchIntervalMillis: 1000, // Set your desired minimum fetch interval
    };
    fetchAndActivate(remoteConfig)
      .then(() => {
        const data = JSON.parse(
          getValue(remoteConfig, "MaintenanceMode").asString()
        );
        setFirebaseData(data);
      })
      .catch((error) => {
        console.error("Error fetching Remote Config:", error);
      })
      ?.finally(() => {
        setFirebaseDataLoading(false);
      });
  };

  useMount(() => {
    fetchRemoteConfigData();
  });

  return (
    <Fragment>
      <GPTVModal
        isBlackButton={true}
      />
      {firebaseDataLoading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Fragment>
          {firebaseData?.isEnabled &&
          !!firebaseData?.target?.find(
            (item) => item === "SuhailPlatformWeb"
          ) ? (
            <UnderMaintenance data={firebaseData} />
          ) : (
            <Router>
              <div
                className={`App ${
                  window.location.href.includes("mobile-metrics")
                    ? "mobile-metrics"
                    : window.location.pathname === "/map" ||
                      window.location.pathname === "/test-map-component"
                    ? "map-component"
                    : null
                }`}
              >
                <Helmet>
                  <meta
                    name="description"
                    content="خريطة عقارية تفاعلية توفر مؤشرات الأسعار العقارية والمعلومات العمرانية
        المستهدفة وغيرها من بيانات عبر الذكاء الإصطناعي
          وذكاء الأعمال , لتتوفر لديك شفافية عالية لكافة القطاع العقاري وتوجهات السوق1"
                  />
                  <title>سهيل</title>
                </Helmet>
                {window.location.pathname === "/map" ||
                window.location.pathname === "/test-map-component" ? (
                  <Fragment>
                    <Suspense
                      fallback={
                        <div className="routes-loader">
                          <Loader
                            type="TailSpin"
                            color="#cacaca"
                            height={50}
                            width={50}
                          />
                        </div>
                      }
                    >
                      <Route
                        path="/map"
                        render={(props) => <pages.MapComponent {...props} />}
                      />
                      <Route
                        path="/test-map-component"
                        render={(props) => (
                          <pages.TestMapComponent {...props} />
                        )}
                      />
                    </Suspense>
                  </Fragment>
                ) : (
                  <>
                    <Header />
                    <Suspense
                      fallback={
                        <div className="routes-loader">
                          <Loader
                            type="TailSpin"
                            color="#cacaca"
                            height={50}
                            width={50}
                          />
                        </div>
                      }
                    >
                      {localStorage.getItem("suhail_user_token")
                        ? ClientRouts()
                        : GestRouts()}
                    </Suspense>
                    {!hideFooter && <Footer />}
                  </>
                )}
              </div>
            </Router>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default App;
